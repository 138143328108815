import "./CarouselComponent.less";
import template from "./CarouselTemplateMobile.html";
import templateOld from "./CarouselTemplateMobileRN.html";
import {Component} from "../../../general/decorators";
import {karaoke} from "../../karaoke";
import BrandingService from "../../../../branding/BrandingService";
import Song from "../../entity/Song";

function asGroup(songs: Song[], groupSize = 2): Song[][] {
    let songsGroup: Song[][] = [];
    for (let i = 0; i < songs.length; i++) {
        const index = Math.floor(i / groupSize);
        if (songsGroup[index] == null) {
            songsGroup[index] = [];
        }
        songsGroup[index].push(songs[i]);
    }
    return songsGroup;
}

@Component({
    controllerAs: '$ctrl',
    transclude: true,
    bindings: {
        songs: "<",
        action: "<",
        songImageUrl: "<",
        title: "<",
        author: "<"
    },
    template: ['BrandingService', function (brandingService: BrandingService) {
        if (["Uztelecom"].indexOf(brandingService.operator) >= 0) {
            return template;
        } else {
            return templateOld;
        }
    }]
})
export default class CarouselComponent {

    static $inject = ['$rootScope', 'BrandingService'];

    public _songs: Song[];
    get songs(): Song[] {
        return this._songs;
    }
    set songs(value: Song[]) {
        this._songs = value;
        this.songsGroup = asGroup(this.songs);
    }

    public action: (Song) => void;
    public title: (Song) => void;
    public songImageUrl: (Song) => string;
    public author: (Song) => void;

    public readonly asGroup: (songs: Song[]) => Song[][];

    public songsGroup: Song[][] = [];

    constructor(
        private readonly $rootScope: karaoke.IRootScopeService,
        private readonly brandingService: BrandingService,
    ) {
        this.songImageUrl = $rootScope.songImageUrl

        // old template
        this.asGroup = asGroup;
    }
}
