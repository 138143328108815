// import "./SearchComponent.less";
import template from "./SearchTemplate.html";

export default {
    transclude: false,
    bindings: {
        value: "=",
        submit: "<",
        close: "<"
    },
    template: template,
    controller: SearchComponentController
};

function SearchComponentController($scope) {

    this.$onInit = () => {
        $scope.doSubmit = () => $scope.$ctrl.submit($scope.$ctrl.value);
        
        if (!!$scope.$ctrl.close) {
            $scope.doClose = ($event) => {
                $event.stopPropagation();
                $scope.$ctrl.close();
            };
        }
    };
}

SearchComponentController.$inject = ["$scope"];