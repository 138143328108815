import Song from "../entity/Song";

export class SearchModel {
    constructor(
        public byName: boolean = true,
        public byGenre: boolean = false,
        public byAuthor: boolean = false
    ) {
    }
}

export class QuickSearch {
    public value: String = "";
    public songs: Song[] = [];
    public searchModel: SearchModel = new SearchModel()
    public doSearch: (string?) => void;

    constructor(
        public go: (string) => void,
        public instantSearch: boolean = true
    ) {
        // this.instantSearch
        this.doSearch = (value:string = null) : void => {
            if (!!value) {
                this.value = value;
            }
            if (this.value.length > 0) {
                go(`/search/${this.value}/`);
            }
            this.value = '';
        };
    }

    /*public doSearch(value: string = null): void {
        if (!!value) {
            this.value = value;
        }
        if (this.value.length > 0) {
            this.go(`/search/${this.value}/`);
        }
        this.value = '';
    };*/
}
