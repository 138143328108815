import BrandingService, {Currency, Price, TarifficationPeriod} from '../BrandingService';
import {Tele2TrialSettings} from "../BrandingService";
// import headerTemplate from './portal-header.html'
import footerTemplate from './portal-footer-t2.html';
import {SongAction} from "../../application/karaoke/services/entity/SongAction";

// function requireAll(r) { r.keys().forEach(r); }
// requireAll(require.context('./images/', true));

const opRes = require.context('../../application/karaoke/', true, /t2\.m\.less/);

export default (application) => {

    application.run((module, angular) => {
        module.factory('BrandingService', () => BrandingService.create("T2")
            .withName('Караоке')
            // PERFORM_BATTLE, при наличии plus-подписки, перетекает в неё
            //.withCoreActions([SongAction.PERFORM, SongAction.PERFORM_RECORD])                            // -
            .withCoreActions([SongAction.PERFORM, SongAction.PERFORM_RECORD, SongAction.PERFORM_BATTLE]) // +
            .withPlusName('Караоке+')                                                                    // +
            .withSubscriptionPrice(new Price(8, Currency.RUB, TarifficationPeriod.DAY))
            .withSubscriptionPlusPrice(new Price(5, Currency.RUB, TarifficationPeriod.DAY))              // +
            // .withSingleTrackPrice(new Price(1, Currency.RUB))
            .withTrafficPrice(new Price(0, Currency.RUB))
            // .hasBasicAuth(require('./page-auth.html'))
            .withTrialSettings(new Tele2TrialSettings())
            //.withKaraokePlusMode('5 руб в день')
            .build());

        // if (NODE_ENV === 'dev') {
        module.run(["$templateCache", ($templateCache) => {
            // $templateCache.put('portal-header.html', headerTemplate);
            $templateCache.put('portal-footer-t2.html', footerTemplate);
        }]);
        // }
    }, opRes);
};