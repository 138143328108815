import BrandingService from "../../../branding/BrandingService";
import {Language} from "../../../branding/Language";

declare let NODE_ENV: String | undefined;

/**
 * Service that depends on HelloService.
 */
export default class SettingsService {
    static $inject = ['BrandingService'];

    private readonly brandingService: BrandingService;

    constructor(
        brandingService: BrandingService
    ) {
        this.brandingService = brandingService;
    }

    public isWaveEnabled(): Boolean {
        // return (NODE_ENV === "prod");
        return true;
    }

    public isStopRecordingOnPause(): Boolean {
        // return (NODE_ENV === "dev");
        // return true;
        return false;
    }

    public isRawDownloadAllowed(): Boolean {
        return (NODE_ENV === "dev");
        // return true;
    }

    public isMixedDownloadAllowed(): Boolean {
        return this.brandingService.operator === 'Tele2' || this.brandingService.operator === 'T2';
        // return true;
    }

    public isFeedbackAllowed(): Boolean {
        return this.brandingService.operator === 'Tele2' || this.brandingService.operator === 'T2';
        // return true;
    }

    public isCounterInPlayerEnabled(): Boolean {
        return this.brandingService.operator === 'MegaFon' ||  this.brandingService.operator === 'Ucell';
    }

    public isRingBackToneAllowed(): Boolean {
        // return this.brandingService.operator === 'Tele2';
        return false;
    }

    public isRawLanguageCodesEnabled(languages: Array<Language>): boolean {
        const enabled = false;
        const multiLang = languages.length > 1;
        const host = location.hostname;
        const hasRawParam = location.search.indexOf("raw") > -1;
        const devHost = host === "127.0.0.1"/* || host.endsWith("-k8s.imt.zone")*/;
        return enabled && multiLang && devHost;
    }
}
